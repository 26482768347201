import { DeleteOutlineOutlinedIcon, ImageOutlinedIcon } from '@assets/icons/Icons'
import { Button } from '@mui/material'
import { FormContextType, useOTFormContext } from '@pages/OTForm/context/OTForm.context'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'

const Dropzone = ({ open }: any) => {
  const { enqueueSnackbar } = useSnackbar()
  const { formCtx, saveFormCtx } = useOTFormContext() as FormContextType
  const removeFile = (file:any) => {
    const newFiles = [...formCtx.ot.files]
    newFiles.splice(newFiles.indexOf(file), 1)

    saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, files: newFiles } })
  }

  // const removeAll = () => {
  //   setFiles([])
  // }
  const ifExist = (file :any) => {
    if (formCtx.ot.files.some(e => e.name === file.name)) {
      return {
        code: 'name-duplicate',
        message: 'El archivo esta duplicado'
      }
    }

    if (file.size > parseInt((process.env.REACT_APP_MAXFILESIZE !== undefined) ? process.env.REACT_APP_MAXFILESIZE : '2097152')) {
      return {
        code: 'big-size',
        message: 'El archivo esta duplicado'
      }
    }

    return null
  }

  const { getRootProps, getInputProps, isDragAccept, isDragReject, fileRejections } = useDropzone({
    maxFiles: 10,
    maxSize: (process.env.REACT_APP_MAXFILESIZE !== undefined) ? parseInt(process.env.REACT_APP_MAXFILESIZE) : 2097152,
    accept: {
      'image/jpg': ['.jpg'],
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg']
    },
    onDrop: acceptedFiles => {
      acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))
      const temp = formCtx.ot.files.concat(acceptedFiles)
      console.log(temp)

      const unique = [...formCtx.ot.files.concat(acceptedFiles)]
      saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, files: unique } })
    },
    validator: ifExist
  })

  const validationFiles = () => {
    if (fileRejections.some((e) => e.errors.some(error => error.code === 'name-duplicate'))) enqueueSnackbar('Ya existe un archivo con este nombre.', { variant: 'warning' })
    if (fileRejections.some((e) => e.errors.some(error => error.code === 'big-size'))) enqueueSnackbar('El archivo excede el tamaño máximo permitido', { variant: 'warning' })
  }

  useEffect(() => {
    validationFiles()
    // Make sure   to revoke the data uris to avoid memory leaks, will run on unmount
    return () => formCtx.ot.files.forEach((file: { preview: string }) => URL.revokeObjectURL(file.preview))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formCtx.ot.files])

  return (
    <section>
      <form encType='multipart/form-data'>
        <div className='h-100 d-flex flex-column'>
          <div {...getRootProps({ className: isDragAccept ? 'p-5 dropzone dropzone-accept' : isDragReject ? 'p-5 dropzone dropzone-reject' : 'p-5 dropzone' })}>
            <input {...getInputProps()} />
            <div className='alert alert-danger text-center p-3' role='alert'>
              <p>El archivo que esta intentando adjuntar no esta permitido</p>
              <hr />
              <p className='mb-0 small'>* Archivos permitidos son: <strong>PNG, JPG o JPEG</strong></p>
            </div>
            <div className='text-center instrucciones'>
              <Button
                variant='contained'
                size='medium'
                color='primary'
                className='m-2'
                onClick={open}
              >
                Seleccione archivo
              </Button>
              <p className='mt-3 mb-0'><strong>Haga click para subir archivos</strong> o arrastre y sueltelos aquí.</p>
              <p>PNG, JPG o JPEG (tamaño máximo 2 mb)</p>
            </div>

          </div>
          <div className='row'>
            {/* {JSON.stringify(files)} */}
            {
              formCtx.ot.files.map((file: any) => (

                <div key={file.nameFile !== undefined ? file.nameFile : file.name} className='col-12'>
                  {/* {JSON.stringify(file)} */}
                  <div className={file.nameFile !== undefined ? 'fileContainer rounded border border-primary mt-3 p-2 d-flex flex-row' : 'fileContainer rounded border border-success mt-3 p-2 d-flex flex-row'}>
                    <div className='fileTypeImg'><ImageOutlinedIcon color='primary' sx={{ fontSize: 25 }} /></div>
                    <div className='fileInfo ms-2'>
                      <p className='small mb-0 fw-bold'>{file.nameFile !== undefined ? file.nameFile : file.name}</p>
                      <p className='small mb-0'>{(file.size / 1024 / 1024).toFixed(3)} Mb</p>
                    </div>
                    <div className='fileActions align-self-end'>
                      <div className='fileActionImg'>
                        <DeleteOutlineOutlinedIcon className='fileTrash' onClick={() => removeFile(file)} color='action' sx={{ fontSize: 25 }} />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </form>

    </section>
  )
}

export default Dropzone
