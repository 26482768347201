import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { webApiService } from '@services/index'
// import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import * as XLSX from 'xlsx'

const AssingTechPage = () => {
  const [data, setData] = useState<{loading: boolean, html: string, userList: any[], id_assigned_technician: number}>({ loading: false, html: '', userList: [], id_assigned_technician: 0 })
  const [otList, setOtList] = useState<any[]>([])
  // const { enqueueSnackbar } = useSnackbar()
  const _poolId = process.env.VITE_COGNITO_POOL_ID || ''

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const validFiles = acceptedFiles.filter(file =>
      ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(file.type)
    )
    console.log('🚀 ~ onDrop ~ validFiles:', validFiles)

    // Asegúrate de que 'files' es de tipo 'File[]'
    const reader = new FileReader()
    reader.onload = (e) => {
      const _data = e.target?.result
      const workbook = XLSX.read(_data, { type: 'binary' })
      console.log(workbook.SheetNames)
      const rowObject : any[] = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])

      setOtList(rowObject)
      console.log('🚀 ~ readExcel ~ rowObject', rowObject)
    }
    // file to blob type
    reader.readAsBinaryString(validFiles[0])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    maxFiles: 1,
    maxSize: (process.env.VITE_MAXFILESIZE !== undefined) ? parseInt(process.env.VITE_MAXFILESIZE) : 2097152,
    accept: {
      'application/vnd.ms-excel': ['.xlsx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    },
    onDrop
  })

  const loadData = async () => {
    setData({ ...data, loading: true })

    webApiService.getAllUsers('3', _poolId).then((response) => {
      setData({
        ...data,
        loading: false,
        userList: response.data
      })
    })
      .catch((error) => {
        console.log(error)
      })
  }

  const asignarTecnico = async () => {
    setData({ ...data, loading: true })
    let html = '----- INICIO -----<br/>'
    for (const item of otList) {
      const objAssigned = {
        ot: item.ot,
        id_assigned_technician: data.id_assigned_technician
      }
      console.log('🚀 ~ sendMails ~ objTradeIn:', objAssigned)
      // eslint-disable-next-line no-loop-func
      await webApiService.assingTechnician(objAssigned).then((res) => {
        console.log('🚀 ~ awaitwebApiService.AssignTech ~ res:', res)
        html += '<p>' + item.ot + ' - ' + res.data + '</p>'
        // eslint-disable-next-line no-loop-func
      }).catch((err) => {
        console.log('🚀 ~ awaitwebApiService.sendMails ~ err:', err)
        html += '<p>' + item['Po Id'] + ' - ' + err.response.data.message + '</p>'
      })
      setData({ ...data, html, loading: true })
    }
    html += '----- FIN -----<br/>'
    setData({ ...data, loading: false, html })
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='container-xxl '>
      <div className='row gy-3 my-4'>
        <div className='col-12 my-2'>
          <Typography variant='h6' className='title mb-3'>
            Asignacion de tecnicos
          </Typography>
          <div className='shadow-sm rounded bg-white p-3'>
            <InputLabel id='demo-simple-select-label'>Excel con listado de ordenes para asignar o reasignar:</InputLabel>
            <div hidden={otList.length > 0} {...getRootProps({ className: isDragAccept ? 'p-5 dropzone dropzone-accept' : isDragReject ? 'p-5 dropzone dropzone-reject' : 'p-5 dropzone' })}>
              <input {...getInputProps()} />
              <div className='alert alert-danger text-center p-3' role='alert'>
                <p>El archivo que esta intentando adjuntar no esta permitido</p>
                <hr />
                <p className='mb-0 small'>* Archivos permitido es: <strong>XLSX</strong></p>
              </div>
              <div className='text-center instrucciones'>

                <Button
                  variant='contained'
                  size='medium'
                  color='secondary'
                  className='m-2'
                >
                  Seleccione archivos
                </Button>
                <p className='mt-3 mb-0'><strong>Haga click para subir el archivo</strong> o arrastre y suelte aquí.</p>
                <p>XLSX (tamaño máximo 2 mb)</p>
              </div>
            </div>
            {otList.map((item: any, idx: number) => {
              return (<span key={idx} className='badge rounded-pill text-bg-secondary me-1'>{item.ot}</span>)
            })}
            <div hidden={otList.length === 0}>
              <Button size='small' onClick={() => { setOtList([]) }}> Cargar excel nuevamente</Button>
            </div>

            <div />
            <FormControl fullWidth className='mt-3'>
              <InputLabel id='demo-simple-select-label'>Asignar Técnico</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Asignar Técnico'
                value={data.id_assigned_technician === 0 ? '' : String(data.id_assigned_technician)}
                onChange={(event: SelectChangeEvent) => setData({ ...data, id_assigned_technician: Number(event.target.value) })}
              >
                {
                    data.userList.map(element => {
                      return (<MenuItem key={element.id} value={element.id}>{element.name}</MenuItem>)
                    })
                }
              </Select>
            </FormControl>
            <div className='text-end'>
              <Button
                variant='contained'
                color='primary'
                className='mt-2'
                onClick={() => { asignarTecnico() }}
                disabled={otList.length === 0 || data.id_assigned_technician === 0 || data.loading}
              >
                ASIGNAR
              </Button>
            </div>
            <div className={data.html === '' ? 'bg-black p-0 text-white mt-2' : 'bg-black p-2 text-white mt-2'} dangerouslySetInnerHTML={{ __html: data.html }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssingTechPage
