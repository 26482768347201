export const PublicRoutes = {
  LOGIN: '/login'
}

export const PrivateRoutes = {
  OTLIST: '/ot-list',
  OTFORM: '/ot-form/',
  OTFORMIMEI: '/ot-form-imei/',
  OTREPORT: '/ot-report',
  OTREPORTSTATUS: '/ot-report-status',
  OTSUPPLIESREPORT: '/ot-supplies-report',
  SUPPLIESREPORT: '/supplies-report',
  PROFILE: '/profile',
  OTBLOCKED: '/ot-bloqued',
  SUPPLIES_MANAGEMENT: '/supplies-management/',
  NEW_SUPPLY: '/supplies-management/new',
  EDIT_SUPPLY: '/supplies-management/edit/',
  SUPPLIES_REQUEST: '/supplies-request',
  SUPPLY: '/supply/',
  SUPPLIES_LIST: '/supply/list',
  SUPPLIES_STOCK: '/supply/update-stock',
  THANKS: '/thank-you/',
  SUPPLIES_DELIVERY: '/delivery-supplies/',
  SUPPLIES_DELIVERYOT: '/delivery-supplies-ot/',
  ASSIGNMENTOFTECHNICIANS: '/assignment-of-technicians',
  MASSRESOLUTION: '/mass-resolution'
}
