import { IAppStore } from '@models/store.model'
import { Button, FormControl, FormControlLabel, FormLabel, InputLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { webApiService } from '@services/index'
import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux'
import * as XLSX from 'xlsx'

const MassResolutionPage = () => {
  const [data, setData] = useState<{loading: boolean, html: string, diagnostic_resolution: string}>({ loading: false, html: '', diagnostic_resolution: '' })
  const [otList, setOtList] = useState<any[]>([])
  const userState = useSelector((store: IAppStore) => store.user)

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const validFiles = acceptedFiles.filter(file =>
      ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(file.type)
    )
    console.log('🚀 ~ onDrop ~ validFiles:', validFiles)

    // Asegúrate de que 'files' es de tipo 'File[]'
    const reader = new FileReader()
    reader.onload = (e) => {
      const _data = e.target?.result
      const workbook = XLSX.read(_data, { type: 'binary' })
      console.log(workbook.SheetNames)
      const rowObject : any[] = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])

      setOtList(rowObject)
      console.log('🚀 ~ readExcel ~ rowObject', rowObject)
    }
    // file to blob type
    reader.readAsBinaryString(validFiles[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    maxFiles: 1,
    maxSize: (process.env.VITE_MAXFILESIZE !== undefined) ? parseInt(process.env.VITE_MAXFILESIZE) : 2097152,
    accept: {
      'application/vnd.ms-excel': ['.xlsx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    },
    onDrop
  })

  const massResolution = async () => {
    setData({ ...data, loading: true })
    let html = '----- INICIO -----<br/>'
    for (const item of otList) {
      const objResolution = {
        ot: item.ot,
        diagnostic_resolution: data.diagnostic_resolution,
        updated_by: userState.id
      }
      console.log('🚀 ~ sendMails ~ objTradeIn:', objResolution)
      // eslint-disable-next-line no-loop-func
      await webApiService.massResolution(objResolution).then((res) => {
        console.log('🚀 ~ awaitwebApiService.AssignTech ~ res:', res)
        html += '<p>' + item.ot + ' - ' + res.data + '</p>'
        // eslint-disable-next-line no-loop-func
      }).catch((err) => {
        console.log('🚀 ~ awaitwebApiService.sendMails ~ err:', err)
        html += '<p>' + item['Po Id'] + ' - ' + err.response.data.message + '</p>'
      })
      setData({ ...data, html, loading: true })
    }
    html += '----- FIN -----<br/>'
    setData({ ...data, loading: false, html })
  }

  return (
    <div className='container-xxl '>
      <div className='row gy-3 my-4'>
        <div className='col-12 my-2'>
          <Typography variant='h6' className='title mb-3'>
            Resolver OT Phonecheck
          </Typography>
          <div className='shadow-sm rounded bg-white p-3'>
            <InputLabel id='demo-simple-select-label'>Excel con listado de ordenes a resolver:</InputLabel>
            <div hidden={otList.length > 0} {...getRootProps({ className: isDragAccept ? 'p-5 dropzone dropzone-accept' : isDragReject ? 'p-5 dropzone dropzone-reject' : 'p-5 dropzone' })}>
              <input {...getInputProps()} />
              <div className='alert alert-danger text-center p-3' role='alert'>
                <p>El archivo que esta intentando adjuntar no esta permitido</p>
                <hr />
                <p className='mb-0 small'>* Archivos permitido es: <strong>XLSX</strong></p>
              </div>
              <div className='text-center instrucciones'>

                <Button
                  variant='contained'
                  size='medium'
                  color='secondary'
                  className='m-2'
                >
                  Seleccione archivo
                </Button>
                <p className='mt-3 mb-0'><strong>Haga click para subir el archivo</strong> o arrastre y suelte aquí.</p>
                <p>XLSX (tamaño máximo 2 mb)</p>
              </div>
            </div>
            {otList.map((item: any, idx: number) => {
              return (<span key={idx} className='badge rounded-pill text-bg-secondary me-1'>{item.ot}</span>)
            })}
            <div hidden={otList.length === 0}>
              <Button size='small' onClick={() => { setOtList([]) }}> Cargar excel nuevamente</Button>
            </div>

            <div className='col-12 col-lg-4'>
              <FormControl className='mt-2'>
                <FormLabel id='demo-radio-buttons-group-label fw-bold'>
                  <b>Resolución</b>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby='demo-radio-buttons-group-label'
                  name='radio-buttons-group'
                  value={data.diagnostic_resolution}
                  onChange={(e) => { setData({ ...data, diagnostic_resolution: e.target.value }) }}
                >
                  <FormControlLabel
                    value='REPARADO' control={<Radio />} label='REPARADO'
                  />
                  <FormControlLabel
                    value='SIN REPARACIÓN' control={<Radio />} label='SIN REPARACIÓN'
                  />
                </RadioGroup>
              </FormControl>

            </div>
            <div className='text-end'>
              <Button
                variant='contained'
                color='primary'
                className='mt-2'
                onClick={() => { massResolution() }}
                disabled={otList.length === 0 || data.diagnostic_resolution === '' || data.loading}
              >
                Guardar
              </Button>
            </div>
            <div className={data.html === '' ? 'bg-black p-0 text-white mt-2' : 'bg-black p-2 text-white mt-2'} dangerouslySetInnerHTML={{ __html: data.html }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MassResolutionPage
